import React, { useState, useEffect } from 'react';
import { Dropdown, InputError, Required } from './components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerCancellationsSchema from './registerCancellationsSchema';
import { INSURER_OPTIONS, TYPES, cancellationsReason } from './constants';
import Heading from '../../../../Shared/Heading';
import { addCancellationRecord, searchDealerName } from './functions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AuthCheck from '../../../../AuthCheck';
import { Oval } from 'react-loader-spinner';

const RegisterCancellations = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerCancellationsSchema) });

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const [submitting, setSubmitting] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const navigate = useNavigate();

  const [cancellationRegistrationStatus, setCancellationRegistrationStatus] = useState({
    msg: '',
    status: '',
    hideForm: false,
  });

  const registerCancellationRecord = async (claimDetails) => {
    setSubmitting(true);
    const { msg, status } = await addCancellationRecord(claimDetails, name);

    setCancellationRegistrationStatus({
      msg: msg,
      type: status === 200 ? 'success' : 'failed',
      hideForm: status === 200 ? true : false,
    });

    setSubmitting(false);

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/cancellations/view');
      }, 1500);
    }
  };

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    setValue('cancellationParticular', TYPES[0]);
    setValue('insuranceCompanyName', INSURER_OPTIONS[0]);
    setValue('cancellationReason', cancellationsReason[0]);
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      searchDealerName(watch('dealerName')).then(names => setAutoCompleteOptions(names));
    }, 500);

    return () => clearTimeout(id);
  }, [watch('dealerName')])

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title={'Register Cancellation Record'} />

      {cancellationRegistrationStatus.type === 'success' && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{cancellationRegistrationStatus.msg}</div>
        </div>
      )}

      {cancellationRegistrationStatus.type === 'failed' && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{cancellationRegistrationStatus.msg}</div>
        </div>
      )}

      {!cancellationRegistrationStatus.hideForm && (
        <form
          className="flex flex-col items-center justify-center h-full w-full"
          noValidate
          onSubmit={handleSubmit(registerCancellationRecord)}
        >
          <div className="flex flex-wrap gap-4 items-start justify-center w-[900px] m-10 relative">
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Dealer Name <Required />
              </label>
              <input
                {...register('dealerName')}
                type="text"
                onChange={(e) => {
                  setValue('dealerName', e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="MPS"
              />
              <InputError error={errors.dealerName} />
              {getValues('dealerName')?.length >= 3 && autoCompleteOptions?.length > 0 && (
                <div className="absolute top-20 max-h-40 w-[400px] bg-gray-50 z-50 overflow-scroll rounded-lg flex flex-col">
                  {autoCompleteOptions.map((option, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setValue('dealerName', option);
                          setAutoCompleteOptions([]);
                        }}
                        className="border-y-[1px] bg-gray-100 hover:bg-gray-200 cursor-pointer p-2 font-light select-none text-xs"
                      >
                        {option}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Policy Number <Required />
              </label>
              <input
                {...register('policyNumber')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="OG-AAAA-BBBB-1111"
              />
              <InputError error={errors.policyNumber} />
            </div>
            <Dropdown
              options={TYPES}
              label={'Cancellation Particular'}
              id={'cancellationParticular'}
              value={watch('cancellationParticular')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />
            <Dropdown
              options={INSURER_OPTIONS}
              label={'Insurance Company'}
              id={'insuranceCompanyName'}
              value={watch('insuranceCompanyName')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Customer Name <Required />
              </label>
              <input
                {...register('customerName')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="ABC Gupta"
              />
              <InputError error={errors.customerName} />
            </div>
            <Dropdown
              options={cancellationsReason}
              label={'Cancellation Reason'}
              id={'cancellationReason'}
              value={watch('cancellationReason')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Alternate Policy Number <Required />
              </label>
              <input
                {...register('alternatePolicyNumber')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="3142_BC_AAAA_1XX1"
              />
              <InputError error={errors.alternatePolicyNumber} />
            </div>
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Additional Information <Required />
              </label>
              <input
                {...register('additionalInfo')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="....."
              />
              <InputError error={errors.additionalInfo} />
            </div>
            <button
              type="submit"
              className="text-white w-[300px] bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={submitting}
            >
              Register Cancellation Record <i className="fa-solid fa-circle-check"></i>{' '}
            </button>
            {submitting && (
              <Oval
                visible={true}
                height="32"
                width="32"
                color="#3B82F6"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default RegisterCancellations;
