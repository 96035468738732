import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as xlsx from 'xlsx';

import { setGeneratingReport, setPriceLists, setPricelistsFilterdView } from './redux.js';
import { auth, getPriceListRequests, pricelistFilteredView, updatePickedBy } from './functions.js';
import { AMAN_RMS, AMJAD_RMS, MADHAV_RMS, POLLING_INTERVAL, getTableHeaders } from './constants.js';
import { DownloadReport, FilterMenu, LinkButtonPricelist, SearchPriceList, SubmitPriceListButton, TableRow } from './components.jsx';
import Heading from '../../../Shared/Heading.jsx';
import axios from 'axios';
import { URL } from '../../../env.js';
import LinkButton from '../../../Shared/LinkButton.jsx';
import AuthCheck from '../../../AuthCheck.jsx';
import dayjs from 'dayjs';

const PriceLists = () => {
  const [filter, setFilter] = useState(['all', 'pending', 'on hold', 'completed']);
  const [query, setQuery] = useState('');
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [modalData, setModalData] = useState();

  const { priceLists, filteredPricelists } = useSelector(({ priceListData }) => {
    return priceListData;
  });
  const user = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //! Fetch Records
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const { records } = await getPriceListRequests('', '', query);

      let pricelistsRequests = records;
      if (user.name === 'Madhav' || user.name === 'Amjad Pathan' || user.name === 'Aman Kumar') {
        const filteredRecords = records.filter((record) => {
          let rms;
          if(user.name === 'Aman Kumar'){
            rms = AMAN_RMS;
          } else if (user.name === 'Madhav'){
            rms = MADHAV_RMS;
          } else {
            rms = AMJAD_RMS;
          }
          return rms.includes(record.sales_manager);
        });

        pricelistsRequests = filteredRecords;
      }


      dispatch(setPriceLists(pricelistsRequests));
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [dispatch, query, user.name]);

  const { pathname } = useLocation();

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title="Price Lists" />

      {/* Filters */}
      {/* <FilterMenu filter={filter} setFilter={setFilter} /> */}

      {/* Submit Price List Button */}
      {
        ['ADMIN', 'SALES'].includes(user.department) &&
        <div data-dial-init className="fixed end-6 bottom-6 group">
          <button onClick={() => {
            navigate('/dealer/upload/pricelist')
          }} type="button" data-dial-toggle="speed-dial-menu-bottom-right" aria-controls="speed-dial-menu-bottom-right" aria-expanded="false" className="flex items-center justify-center text-white bg-green-500 rounded-full w-14 h-14 hover:bg-green-800 dark:bg-green-500 dark:hover:bg-green-600 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-green-800">
            <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
            </svg>
            <span className="sr-only">Open actions menu</span>
          </button>
        </div>
      }



      {(['Gaurav Arora', 'Kapil Vashist', 'Priyanka Teotia']).includes(user.name) && <button
        onClick={async () => {
          dispatch(setGeneratingReport(true));
          const reportData = await axios.get(`${URL}/bst/generate-report`);

          if (reportData.status === 200) {
            try {
              const wb = xlsx.utils.book_new();
              const priceListsData = xlsx.utils.aoa_to_sheet(reportData.data.REPORT_DATA);
              xlsx.utils.book_append_sheet(wb, priceListsData, `Price List Logs`);

              const fileName = `Price-List-Logs.xlsx`;
              xlsx.writeFile(wb, fileName);
            } catch (err) {
              console.log(err);
            }
          }
        }}
        type="button"
        className="font-bold text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 mx-2 rounded-lg mt-2 text-sm px-5 py-2.5 flex items-center dark:focus:ring-gray-500"
      > Generate Report <i className="fa-solid fa-file text-green-600 ml-2"></i> </button>}
      {/* </div> */}

      {
        (user.name === 'Madhav' || user.name === 'Aman Kumar') && <DownloadReport team={user.team} setDownloadingReport={setDownloadingReport} downloadingReport={downloadingReport}/>
      }

      {
        ['Kapil Vashist', 'Madhav', 'Gaurav Arora', 'Amjad Pathan', 'Aman Kumar'].includes(user.name) &&
        <button onClick={() => {
          navigate('/revenue/rm/stats')
        }} type="button" className="font-bold text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 mx-2 rounded-lg mt-2 text-sm px-5 py-2.5 flex items-center dark:focus:ring-gray-500"> View RM Stats <i className="fa-solid fa-chart-simple ml-2"></i> </button>
      }

      <SearchPriceList pricelistFilteredView={pricelistFilteredView} department={user.department} user={user.name} />

      {/* Table */}
      {/* <div className="relative m-10 overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-max text-center mx-auto text-sm rtl:text-right text-gray-500 dark:text-gray-400">

          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center"></div>
              </th>
              {getTableHeaders(user.department).map((header, index) => (
                <th key={index} scope="col" className="px-4 py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          
            {
              (filteredPricelists ? filteredPricelists : priceLists) &&
              (filteredPricelists ? filteredPricelists : priceLists).map((record) => {
                return filter.includes(record.status) && (record.sales_manager_email === user.email || user.department !== 'SALES') ? <TableRow key={record.id} record={record} user={user} /> : null
              })}
          </tbody>
        </table>
      </div> */}

      {/* Card View */}
      {/* Card Status */}
      <div className={`grid grid-cols-4 text-center mx ${modalData ? 'opacity-40' : ''}`}>
        <div>
          <h1 className='bg-yellow-300 font-bold p-3 shadow-lg rounded-lg m-2'>Pending</h1>
        </div>
        <div>
          <h1 className='bg-yellow-200 font-bold p-3 shadow-lg rounded-lg m-2'>Reworking Pending</h1>
        </div>
        <div>
          <h1 className='bg-green-500 font-bold p-3 shadow-lg rounded-lg m-2'>Completed</h1>
        </div>
        <div>
          <h1 className='bg-red-500 font-bold p-3 shadow-lg rounded-lg m-2'>On Hold</h1>
        </div>
      </div>

      {/* Cards */}
      <div className={`grid grid-cols-4 text-center mx ${modalData ? 'opacity-40' : ''}`}>
        <div className='p-3 overflow-y-scroll max-h-screen '>
          {
            (filteredPricelists ? filteredPricelists : priceLists).map((record) => {
              if (record.status === 'pending' && record.reworkings === 0 && (record.sales_manager_email === user.email || user.department !== 'SALES' || user.name === 'Madhav' || user.name === 'Amjad Pathan' || user.name === 'Aman Kumar')) {
                return (
                  <div className='bg-gray-600 text-white font-bold outline hover:cursor-pointer overscroll-x-contain text-[12px] shadow-lg mt-4 rounded-lg p-4 card text-left'>
                    <p> Dealer Name : {record.dealer_name}</p>
                    <p> RTO : {record.dealer_rto}</p>
                    <p> Pincode : {record.dealer_pincode}</p>
                    <p> Sales Manager : {record.sales_manager}</p>
                    <p> Manufacturer Name : {record.manufacturer_name}</p>
                    <br />
                    <p> Arrived At: {dayjs(record.submitted_at, { utc: true }).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <div className='my-2'>
                      {
                        ['BUSINESS SUPPORT', 'ADMIN'].includes(user.department) &&
                          !record.picked_by ? <button onClick={() => updatePickedBy('NP', record, user.name, dispatch, setPriceLists)} className='bg-blue-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-hand mr-2"></i> Pick Up</button> : <button onClick={() => {
                            if (record.picked_by === user.name) {
                              updatePickedBy('P', record, user.name, dispatch, setPriceLists)
                            }
                          }} className='bg-green-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-person-digging mr-2"></i> {record.picked_by}</button>
                      }
                      <button onClick={() => {
                        setModalData(record);
                      }} className='bg-blue-600 text-white p-2 rounded-lg mx-2'> <i className="fa-solid fa-eye mr-1"></i> View Details</button>
                    </div>
                  </div>
                )
              }
            })
          }

        </div>
        <div className='p-3 overflow-y-scroll max-h-screen '>
          {
            (filteredPricelists ? filteredPricelists : priceLists).map((record) => {
              if (record.status === 'pending' && parseInt(record.reworkings) > 0 && (record.sales_manager_email === user.email || user.department !== 'SALES' || user.name === 'Madhav' || user.name === 'Amjad Pathan' || user.name === 'Aman Kumar')) {
                return (
                  <div className='bg-gray-600 text-white font-bold outline hover:cursor-pointer overscroll-x-contain text-[12px] shadow-lg mt-4 rounded-lg p-4 card text-left'>
                    <p> Dealer Name : {record.dealer_name}</p>
                    <p> RTO : {record.dealer_rto}</p>
                    <p> Pincode : {record.dealer_pincode}</p>
                    <p> Sales Manager : {record.sales_manager}</p>
                    <p> Manufacturer Name : {record.manufacturer_name}</p>
                    <br />
                    <p> Arrived At: {dayjs(record.submitted_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <div className='my-2'>
                      {
                        ['BUSINESS SUPPORT', 'ADMIN'].includes(user.department) &&
                          !record.picked_by ? <button onClick={() => updatePickedBy('NP', record, user.name, dispatch, setPriceLists)} className='bg-blue-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-hand mr-2"></i> Pick Up</button> : <button onClick={() => {
                            if (record.picked_by === user.name) {
                              updatePickedBy('P', record, user.name, dispatch, setPriceLists)
                            }
                          }} className='bg-green-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-person-digging mr-2"></i> {record.picked_by}</button>
                      }
                      <button onClick={() => {
                        setModalData(record);
                      }} className='bg-blue-600 text-white p-2 rounded-lg mx-2'> <i className="fa-solid fa-eye mr-1"></i> View Details</button>
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
        <div className='p-3 overflow-y-scroll max-h-screen '>
          {
            (filteredPricelists ? filteredPricelists : priceLists).map((record) => {
              if (record.status === 'completed' && (record.sales_manager_email === user.email || user.department !== 'SALES' || user.name === 'Madhav' || user.name === 'Amjad Pathan' || user.name === 'Aman Kumar')) {
                return (
                  <div className='bg-gray-600 text-white font-bold outline hover:cursor-pointer overscroll-x-contain text-[12px] shadow-lg mt-4 rounded-lg p-4 card text-left'>
                    <p> Dealer Name : {record.dealer_name}</p>
                    <p> RTO : {record.dealer_rto}</p>
                    <p> Pincode : {record.dealer_pincode}</p>
                    <p> Sales Manager : {record.sales_manager}</p>
                    <p> Manufacturer Name : {record.manufacturer_name}</p>
                    <p> Working Done By : {record.uploaded_by}</p>
                    <br />
                    <p>Arrived At: {dayjs(record.submitted_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <p> Submitted At : {dayjs(record.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <div className='my-2'>
                      {
                        ['SALES', 'ADMIN'].includes(user.department) &&
                        <LinkButton
                          link={
                            `/dealer/pricelist/edit/${record.id}`
                          }
                          title={<><i className="fa-solid fa-pen-to-square mr-2"></i> <span>Revise Request</span></>}
                        />
                      }
                      <button onClick={() => {
                        setModalData(record);
                      }} className='bg-blue-600 text-white p-2 rounded-lg mx-2'> <i className="fa-solid fa-eye mr-1"></i> View Details</button>

                    </div>
                  </div>
                )
              }
            })
          }
        </div>
        <div className='p-3 overflow-y-scroll max-h-screen '>
          {
            (filteredPricelists ? filteredPricelists : priceLists).map((record) => {
              if (record.status === 'on hold' && (record.sales_manager_email === user.email || user.department !== 'SALES' || user.name === 'Madhav' || user.name === 'Amjad Pathan' || user.name === 'Aman Kumar')) {
                return (
                  <div className='bg-gray-600 text-white font-bold outline hover:cursor-pointer overscroll-x-contain text-[12px] shadow-lg mt-4 rounded-lg p-4 card text-left'>
                    <p> Dealer Name : {record.dealer_name}</p>
                    <p> RTO : {record.dealer_rto}</p>
                    <p> Pincode : {record.dealer_pincode}</p>
                    <p> Sales Manager : {record.sales_manager}</p>
                    <p> Manufacturer Name : {record.manufacturer_name}</p>
                    <p> On Hold By : {record.uploaded_by}</p>
                    <br />
                    <p>Arrived At: {dayjs(record.submitted_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <p> Submitted At : {dayjs(record.updated_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                    <div className='my-2'>
                      {
                        ['SALES', 'ADMIN'].includes(user.department) &&
                        <LinkButton
                          link={
                            `/dealer/pricelist/edit/${record.id}`
                          }
                          title={<><i className="fa-solid fa-pen-to-square mr-2"></i> <span>Revise Request</span></>}
                        />
                      }
                      <button onClick={() => {
                        setModalData(record);
                      }} className='bg-blue-600 text-white p-2 rounded-lg mx-2'> <i className="fa-solid fa-eye mr-1"></i> View Details</button>
                    </div>
                  </div>
                )
              }
            })
          }
        </div>
      </div>

      {/* Modal */}
      {
        modalData &&
        <div id="large-modal" tabindex="-1" className="fixed z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-full max-w-7xl max-h-full">
            <div className="relative rounded-lg shadow bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                <h3 className="text-xl font-medium text-white">
                  {modalData.dealer_name} ( {modalData.id} )
                </h3>
                <button onClick={() => {
                  setModalData();
                }} type="button" className="text-red-500 font-bold bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-red-500" data-modal-hide="large-modal">
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 grid grid-cols-2 gap-y-5 gap-x-7">

                {/* Dealership Details */}
                <div className="text-base leading-relaxed text-gray-400">
                  <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Dealership Details</h1>
                  <div>
                    <li><span className="text-gray-100 underline">Dealership Name</span> : <span>{modalData?.dealer_name}</span> </li>
                    <li><span className="text-gray-100 underline">Dealership RTO</span> : <span>{modalData?.dealer_rto}</span> </li>
                    <li><span className="text-gray-100 underline">Dealership Pincode</span> : <span>{modalData?.dealer_pincode}</span> </li>
                    <li><span className="text-gray-100 underline">Dealership Manufacturer</span> : <span>{modalData?.manufacturer_name}</span> </li>
                  </div>
                </div>
                {/* Dealership Details */}

                {/* Payout Details */}
                <div className="text-base leading-relaxed text-gray-400">
                  <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Payout Details</h1>
                  <div>
                    <li><span className="text-gray-100 underline">Dealer Current Payout BIKE</span> : <span>{modalData?.current_dealer_payout_bike}</span> </li>
                    <li><span className="text-gray-100 underline">Dealer Current Payout SCOOTER</span> : <span>{modalData?.current_dealer_payout_scooter}</span> </li>
                    <li><span className="text-gray-100 underline">Dealer Required Payout BIKE </span> : <span>{modalData?.required_payout_bike}</span> </li>
                    <li><span className="text-gray-100 underline">Dealer Required Payout SCOOTER </span> : <span>{modalData?.required_payout_scooter}</span> </li>
                    <li><span className="text-gray-100 underline">Dealer Current Insurer </span> : <span>{modalData?.current_dealer_insurer}</span> </li>
                  </div>
                </div>
                {/* Payout Details */}

                {/* Other Details */}
                <div className="text-base leading-relaxed text-gray-400">
                  <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Other Requirements / Details</h1>
                  <div>
                    <li><span className="text-gray-100 underline">Nil Dep Preferrences</span> : <span>{modalData?.nil_dep_preferences}</span> </li>
                    {
                      JSON.parse(modalData?.other_requirements) && <li><span className="text-gray-100 underline">Requirement Comments</span> : <span>{JSON.parse(modalData?.other_requirements)?.[JSON.parse(modalData?.other_requirements).length - 1] === '' ? "*No Requirements Given* (System Generated)" : JSON.parse(modalData?.other_requirements)?.[JSON.parse(modalData?.other_requirements).length - 1]}</span> </li>
                    }
                    {modalData?.expected_bikes ? <li><span className="text-gray-100 underline">Expected Bike Count</span> : <span>{modalData?.expected_bikes}</span> </li> : null}
                    {modalData?.expected_scooters ? <li><span className="text-gray-100 underline">Expected Scooter Count</span> : <span>{modalData?.expected_scooters}</span> </li> : null}
                  </div>
                </div>
                {/* Other Details */}

                {/* Uploaded Documents */}
                <div className="text-base leading-relaxed text-gray-400">
                  <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Documents</h1>
                  <div>
                    <LinkButton link={`https://drive.google.com/file/d/${modalData?.policy_copy_1}/view`} title={<span> <i className="fa-solid fa-eye"></i> Policy Copy File ( I )</span>} type={'file'}></LinkButton>
                    <LinkButton link={`https://drive.google.com/file/d/${modalData?.policy_copy_2}/view`} title={<span> <i className="fa-solid fa-eye"></i> Policy Copy File ( II )</span>} type={'file'}></LinkButton>
                    <LinkButton link={`https://drive.google.com/file/d/${modalData?.price_list}/view`} title={<span> <i className="fa-solid fa-eye"></i> Price List</span>} type={'file'}></LinkButton>
                  </div>
                </div>
                {/* Uploaded Documents */}

                {/* View Workings */}
                <div className="text-base leading-relaxed text-gray-400">
                  <h1 className='text-[30px] underline text-white font-extrabold mb-2'>{['BUSINESS SUPPORT', 'ADMIN'].includes(user.department) ? 'Upload / View Workings' : 'View Workings'}</h1>
                  <div>
                    {
                      modalData && JSON.parse(modalData?.ops_revenue_sheet).length > 0 ? <div>
                        {
                          modalData && JSON.parse(modalData?.ops_revenue_sheet).map((file) => {
                            return <>
                            <div>
                              <span className='mr-1/2 my-2 inline-flex'>
                                <div>
                                  <LinkButton type='file' link={`https://drive.google.com/file/d/${file.fileId ? file.fileId : file}/view`} title={<><i className="fa-solid fa-eye mr-2"></i> <span>Working</span></>} />
                                </div>

                                {
                                  ['BUSINESS SUPPORT', 'ADMIN'].includes(user.department) &&
                                  <>
                                    <button id={file.fileId} onClick={async (e) => {
                                      const fileId = file.fileId ? file.fileId : file;
                                      const requestId = modalData?.id;

                                      const options = {
                                        url: `${URL}/bst/delete-file`,
                                        headers: {
                                          'content-type': 'application/json'
                                        },
                                        method: 'POST',
                                        data: JSON.stringify({ fileId, requestId })
                                      };

                                      const request = axios.request(options);
                                      const { status } = (await request);

                                      if (status === 200) {
                                        const { records } = await getPriceListRequests(user.email, user.department, '');
                                        dispatch(setPriceLists(records));

                                        // Get updated data & set fresh data in modal
                                        const id = modalData.id;
                                        const newModalData = records.filter((record) => {
                                          return record.id === id;
                                        });

                                        setModalData(newModalData[0]);
                                      }

                                    }} key={file} className='hover:text-red-800 p-1 bg-blue-600 rounded-lg'><i className="fa-solid fa-trash text-red-600"></i></button>
                                  </>
                                }
                              </span> 

                              {
                                file.uploadedBy && 
                                <span className='p-2'>Uploaded By: {file.uploadedBy}</span>
                              }
                            </div>
                            </>
                          })}
                        <LinkButton
                          type={''}
                          link={`/dealer/pricelist/upload-working/${modalData?.id}`}
                          title={<><i className="fa-solid fa-square-plus"></i></>}
                        />
                      </div>
                        : ['BUSINESS SUPPORT', 'ADMIN'].includes(user.department) ?
                          <LinkButton type='' link={`/dealer/pricelist/upload-working/${modalData?.id}`} title={<> <i className="fa-solid fa-upload mr-2"></i> <span>Upload Payout Working Sheet </span></>} /> : 'No Workings Uploaded Yet'
                    }
                  </div>
                  {
                    modalData?.comments.length > 0 && <div className='my-4'><span className="text-gray-100 underline">Comments </span> : <span>{modalData?.comments}</span></div>
                  }
                </div>
                {/* View Workings */}
                {/* On Board Dealer */}
                {
                  (modalData.onboarding_request) && ['ADMIN', 'SALES'].includes(user.department) && JSON.parse(modalData?.ops_revenue_sheet).length > 0 &&
                  <div className="text-base leading-relaxed text-gray-400">
                    <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Dealer Onboarding</h1>
                    <div>
                      {
                        modalData.onboarding_request === 'available' ? <LinkButton type={''} link={`/dealer/onboarding/${modalData?.id}`} title={<><i className="fa-solid fa-handshake mr-1"></i> On Board Dealer </>}
                        /> : modalData.onboarding_request === 'onboarded' ? <LinkButton type={''} link={``} title={<><i className="fa-solid fa-circle-check mr-1"></i> Dealer Onboarded</>} /> : modalData.onboarding_request === 'rejected' ? <div>
                          <LinkButton type={''} link={``} title={<><i className="fa-solid fa-rectangle-xmark text-red-500 mr-1"></i> Rejected</>} />
                          <LinkButton type={''} link={`/dealer/onboarding/edit/${modalData?.id}`} title={<><i className="fa-solid text-green-300 fa-pen mr-1"></i> Edit Re-Submit</>} />
                          <div className='my-2'>
                            <span className="text-gray-100 underline">Rejection Comments</span> : <span>{modalData?.onboarding_rejection_reason}</span>
                          </div>
                        </div> : <LinkButton type={''} link={``} title={<><i className="fa-solid fa-hourglass-half mr-1"></i> Processing Onboarding</>} />
                      }
                    </div>
                  </div>
                }
                {/* On Board Dealer */}
                {/* Comments History */}
                {
                  ['ADMIN', 'BUSINESS SUPPORT'].includes(user.department) &&
                  <div class="text-base leading-relaxed text-gray-400">
                    <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Comments History</h1>
                    <div>
                      {
                        JSON.parse(modalData?.other_requirements) && JSON.parse(modalData?.other_requirements).map((requirement) => {
                          if (requirement === '') {
                            return <li>*No Requirement Provided ( System Generated )*</li>
                          } else {
                            return <li>{requirement}</li>
                          }
                        })
                      }
                    </div>
                  </div>
                }
                {/* Comments History */}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default PriceLists;