const POLLING_INTERVAL = 3000;

const MADHAV_RMS = ["Amandeep","Deepak Kushwaha","Rahul Maindola","Ayush gupta","Abhishek Sharma ( Jaipur )","Arun Kumar Mishra","Abhishek ( UP )", "Mayank Wala", "Madhav", "Virander Singh", "Munindra", "Vikrant Singh"];

const AMJAD_RMS = ["Boobalavelan S","Prashant Kumar","R Balasubramniyan","R. Prathibarajan","Raja Agarwall","Shaik Peer Bhasha","Sidda Prateek Kumar","Srinath G","Deepak","Vipin Ravi","Mojahid Islam","Manual Rixon","Pankaj Saha","Debyendu Saha","Deepak Naik","Devaraddi GN","Amit Kumar Mishra","Bidhan kumar Nayak"];

const AMAN_RMS = ["Amandeep","Deepak Kushwaha","Rahul Maindola","Arun Kumar Mishra","Mayank Wala","Rahul Pathak"] 

const getTableHeaders = (department) => [
    'Request Id',
    ...(['ADMIN', 'BUSINESS SUPPORT'].includes(department)? ['Sales Manager']: []),
    'Dealer Name',
    'Dealer RTO',
    'Dealer Pincode',
    'Manufacturer Name',
    'Dealer\'s Current  Payout Bike',
    'Required Payout Bike',
    'Dealer\'s Current  Payout Scooter',
    'Required Payout Scooter',
    'Nil Dep Preferrences',
    'Dealer\'s Current Insurer',
    // 'Preferred Insurer',
    'Other Requirements',
    'Policy Copy File',
    'Second Policy Copy File',
    'Price List File',
    'Status',
    ...(['ADMIN'].includes(department) ? ['On Board Dealer']: []),
    'Comments',
    ...(['ADMIN', 'BUSINESS SUPPORT'].includes(department)? ['Picked By']: []),
    ...(['ADMIN', 'BUSINESS SUPPORT'].includes(department)? ['Payout Working']: []),
    ...(['ADMIN', 'BUSINESS SUPPORT'].includes(department)? ['No. of Reworkings']: []),
    ...(['ADMIN', 'SALES'].includes(department)? ['Update Request', 'Revenue Sheet']: []),
    'Working Done By',
    'Created At',
    'Updated At'
]

export { POLLING_INTERVAL, getTableHeaders, MADHAV_RMS, AMJAD_RMS, AMAN_RMS }