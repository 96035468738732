import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';

import { URL } from '../../../../../env.js';
import { Oval } from 'react-loader-spinner';
import Pagination from '../../../../../Components/Pagination';

const TABLE_HEADERS = {
  'Dealer Name': 'Dealer Name',
  'Dealer Email': 'Dealer Email',
  'Dealer Code': 'Dealer Code',
  'Dealer Mobile': 'Dealer Mobile',
  'Dealer Email': 'Dealer Email',
  'Dealer Mobile': 'Dealer Mobile',
  'Dealer Type': 'Dealer Type',
  Company: 'Company',
  Address: 'Address',
  City: 'City',
  State: 'State',
  'GM Name': 'GM Name',
  'GM Email': 'GM Email',
  'GM Mobile': 'GM Mobile',
  'Sales Executive': 'Sales Executive',
  'Distributor Name': 'Distributor Name',
  'Allow Negative Balance for ACPL': 'Allow Negative Balance for ACPL',
  'Allow Negative Balance for Insurance': 'Allow Negative Balance for Insurance',
  'Allow Insurance': 'Allow Insurance',
  'Insurance Enabled By': 'Insurance Enabled By',
  'Virtual Account Number': 'Virtual Account Number',
  'Insurance Policies Folder ID': 'Insurance Policies Folder ID',
  'Insurance Policies Sheet ID': 'Insurance Policies Sheet ID',
};

const DealerStats = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const LIMIT = 5;

  const { isLoading: dealersLoading, data } = useQuery({
    queryKey: ['dealersList', pageNumber],
    queryFn: () => {
      return axios
        .get(`${URL}/dealer/dealers-list?pageNumber=${pageNumber}&limit=${LIMIT}`)
        .then((res) => res.data);
    },
    refetchInterval: Infinity,
    staleTime: 60000,
  });

  const { isPending: statsLoading, data: dealersStats } = useQuery({
    queryKey: ['dealersStats'],
    queryFn: () => {
      return axios.get(`${URL}/dealer/dealer-stats`).then((res) => res.data);
    },
    enabled: true,
    refetchInterval: Infinity,
  });

  return (
    <div className="p-10 bg-white w-full rounded-xl shadow-lg relative">
      <div className="flex flex-col">
        <p className="text-lg">Dealer Stats</p>
        {!statsLoading && (
          <div className="flex flex-wrap gap-2 mt-2">
            <div className="bg-orange-100 text-orange-500 px-2 py-1 rounded-full">
              Old Config Dealer: {dealersStats?.oldConfigDealerCount}
            </div>
            <div className="bg-purple-100 text-purple-500 px-2 py-1 rounded-full">
              New Config Dealer: {dealersStats?.newConfigDealerCount}
            </div>
            <div className="bg-teal-100 text-teal-500 px-2 py-1 rounded-full">
              Total A&C Dealers: {dealersStats?.totalActiveAndCodedDealers}
            </div>
            <div className="bg-green-100 text-green-500 px-2 py-1 rounded-full">
              Dealers Active This Month: {dealersStats?.dealersThatDidPolicyThisMonth}
            </div>
          </div>
        )}
        {statsLoading && <Oval color="blue" height={25} width={25} wrapperClass="mt-2" />}
      </div>

      <div className="overflow-scroll mt-5">
        {dealersLoading && <Oval color="blue" height={25} width={25} wrapperClass="mt-2" />}
        {!dealersLoading && (
          <>
            <table className="w-full">
              <thead>
                <tr>
                  {Object.keys(TABLE_HEADERS)?.map((header, index) => (
                    <td
                      key={`dealerListHeader${index}`}
                      className="whitespace-nowrap px-2 text-sm py-1 bg-slate-300 border-2 border-black"
                    >
                      {header}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(data?.dealers || []).map((dealer, index) => {
                  return (
                    <tr key={`dealerListRow${index}${dealer['Dealer Name']}`}>
                      {Object.keys(TABLE_HEADERS)?.map((key, index) => {
                        return (
                          <td
                            key={`dealerListItem${index}${dealer['Dealer Name']}`}
                            className="px-2 py-1 border-2 border-slate-400 whitespace-nowrap"
                          >
                            {dealer[TABLE_HEADERS[key]]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="flex justify-center mt-5">
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          maxPageNumber={Math.floor((data?.total || 0) / LIMIT)}
        />
      </div>
    </div>
  );
};

export default DealerStats;
