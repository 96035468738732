import React, { useState } from 'react';
import Heading from '../../../Shared/Heading.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { registerClaimReport, searchClaimPolicyNumber } from './functions.js';
import { setPolicyRecord, setSearchingRecord } from '../redux.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerClaimSchema from './registerClaimSchema.js';
import { InputError, Required } from './components.jsx';
import dayjs from 'dayjs';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router';

const ClaimRegistration = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerClaimSchema) });


  // Dispatch
  const dispatch = useDispatch();

  // Temp States
  const [searchPolicyNumber, setSearchPolicyNumber] = useState();
  const [policyRecordFound, setPolicyRecordFound] = useState({
    visible: false,
    msg: ''
  });
  const [registerClaim, setRegisterClaim] = useState(false);
  const [claimRegistered, setClaimRegistered] = useState(null);

  // Claims Redux Store
  const { searchingRecord, policyRecord, registeringClaim } = useSelector(({ Claims }) => {
    return Claims;
  });

  // Logged In User Store
  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const navigate = useNavigate();

  return (
    <div>
      {/* Heading */}
      <Heading title={registerClaim ? 'Register Claim' : 'Search Policy Record'} />

      {/* Search Bar */}
      {
        !registerClaim ?
          <div className='max-w-md mx-auto'>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>


              <input disabled={searchingRecord} type="search" onChange={(e) => {
                setSearchPolicyNumber(e.target.value);
              }} id="default-search" className="block w-full p-4 ps-10 text-sm border rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Policy Number" required />

              <button
                disabled={searchingRecord || !searchPolicyNumber}
                onClick={async () => {
                  dispatch(setSearchingRecord(true));
                  dispatch(setPolicyRecord(null));
                  const status = await searchClaimPolicyNumber(searchPolicyNumber, dispatch);

                  status === 'Policy Record Found' ? setPolicyRecordFound({ visible: true, msg: status.msg }) : setPolicyRecordFound({ visible: true, msg: status.msg });

                  setTimeout(() => {
                    setPolicyRecordFound({ visible: false, msg: status.msg });
                  }, 3000);

                }}
                type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:text-white">
                {
                  searchingRecord ? <>
                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-black animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                    <span>Searching...</span> </> : <p>Search</p>
                }
              </button>

            </div>
          </div> : null
      }

      {/* Notifier */}
      {
        policyRecordFound.visible &&
        <div className='my-2'>
          {
            policyRecordFound.msg === 'Policy Record Found' ? <div id="toast-success" class="mx-auto flex items-center w-fit p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
              <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="sr-only">Check icon</span>
              </div>
              <div class="ms-3 text-sm font-normal">{policyRecordFound.msg}</div>
              <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                <span class="sr-only">Close</span>
              </button>
            </div> : <div id="toast-danger" class="mx-auto  flex items-center w-fit    p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
              <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                </svg>
                <span class="sr-only">Error icon</span>
              </div>
              <div class="ms-3 text-sm font-normal">{policyRecordFound.msg}</div>
              <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
                <span class="sr-only">Close</span>
              </button>
            </div>
          }



        </div>
      }

      {/* Details Section */}
      {
        policyRecord &&
        <div className='rounded-lg'>
          <h1 className='underline font-bold text-[30px] p-2'>Insurance Policy Details</h1>
          <div className="sections grid grid-cols-2 mx-auto">
            <div className='p-3 bg-gray-700 rounded-lg m-3 text-white'>
              <h1 className='underline font-bold text-[20px]'>Customer Details</h1>
              <ul className='p-2'>
                <li>Name : {policyRecord?.customerDetails?.name}</li>
                <li>Mobile : {policyRecord?.customerDetails?.mobile}</li>
                <li>City : {policyRecord?.customerDetails?.city}</li>
                <li>RTO : {policyRecord?.customerDetails?.rto}</li>
                <li>State : {policyRecord?.customerDetails?.state}</li>
              </ul>
            </div>
            <div className='p-3 bg-gray-700 rounded-lg m-3 text-white'>
              <h1 className='underline font-bold text-[20px]'>Insurance Policy Details</h1>
              <ul className='p-2'>
                <li>Company Name : {policyRecord?.insuranceDetails?.insurer}</li>
                <li>Branch : {policyRecord?.insuranceDetails?.branch}</li>
                <li>Policy Number : {policyRecord?.insuranceDetails?.policyNumber}</li>
              </ul>
            </div>
            <div className='p-3 bg-gray-700 rounded-lg m-3 text-white'>
              <h1 className='underline font-bold text-[20px]'>Vehicle Details</h1>
              <ul className='p-2'>
                <li>Name : {policyRecord?.vehicleDetails?.name}</li>
                <li>Registration No. : {policyRecord?.vehicleDetails?.vehicleRegistrationNumber ? policyRecord?.vehicleDetails?.vehicleRegistrationNumber : 'NEW'}</li>
              </ul>
            </div>
            <div className='p-3 bg-gray-700 rounded-lg m-3 text-white'>
              <h1 className='underline font-bold text-[20px]'>Dealer Details</h1>
              <ul className='p-2'>
                <li>Name : {policyRecord?.dealerDetails?.name}</li>
              </ul>
            </div>
          </div>

          {
            registerClaim &&
            <form className='grid grid-cols-5 m-4' onSubmit={handleSubmit(async (e) => {
              const { msg } = await registerClaimReport(e, policyRecord, name, dispatch);

              if (msg.includes('Success')) {
                setClaimRegistered({
                  type: true,
                  msg
                });
                dispatch(setPolicyRecord(null));

                
                
                setTimeout(() => {
                  navigate('/claims/view');
                }, 1500);
              } else {
                setClaimRegistered({
                  type: false,
                  msg
                });

                setTimeout(() => {
                  setClaimRegistered(null);
                }, 3000)
              }
            })} noValidate>
              <div>
                <label htmlFor="claimtype" className='block font-bold'> Claim Type <Required /></label>
                <select {...register('claimType')} id="claimtype" className='text-sm rounded-lg bg-gray-700 text-white w-3/4'>
                  <option value="">Select Claim Type</option>
                  <option value="Accidental">Accidental</option>
                  <option value="Theft">Theft</option>
                  <option value="Partial Theft">Partial Theft</option>
                </select>
                <InputError error={errors.claimType} />
              </div>
              <div>
                <label htmlFor="workshop-name" className='block font-bold'> Workshop Name <Required /></label>
                <input {...register('workshopName')} type="text" id='workshop-name' className='text-sm rounded-lg bg-gray-700 text-white w-3/4' />
                <InputError error={errors.workshopName} />
              </div>
              <div>
                <label htmlFor="workshop-address" className='block font-bold'> Workshop Address <Required /></label>
                <input {...register('workshopAddress')} type="text" id='workshop-address' className='text-sm rounded-lg bg-gray-700 text-white w-3/4' />
                <InputError error={errors.workshopAddress} />
              </div>
              <div>
                <label htmlFor="estimate" className='block font-bold'> Workshop Estimate <Required /></label>
                <input {...register('workshopEstimate')} type="text" id='estimate' className='text-sm rounded-lg bg-gray-700 text-white w-3/4' />
                <InputError error={errors.workshopEstimate} />
              </div>
              <div>
                <label htmlFor="claimDate" className='block font-bold'> Claim Receieve Date <Required /></label>
                <input
                  max={dayjs().format('YYYY-MM-DD')}
                  min={dayjs().subtract(40, 'day').format('YYYY-MM-DD')}
                  {...register('claimReceiveDate')}
                  type="date" id='claimDate' className='text-sm rounded-lg bg-gray-700 text-white w-3/4' />
                <InputError error={errors.claimReceiveDate} />
              </div>
              <div className='my-2 flex align-middle'>
                <button disabled={registeringClaim} type='submit' className='p-2 bg-green-500 rounded-lg font-semibold text-sm'> <i class="fa-solid fa-pen-nib mr-2"></i>Submit Claim Report</button>
                {
                  registeringClaim &&
                  <Oval
                    height={'32px'}
                    width={'40px'}
                  />
                }
              </div>
            </form>
          }

          {
            !registerClaim &&
            <button onClick={() => {
              if (!registerClaim) {
                setRegisterClaim(true);
              }
            }} className='p-2 bg-green-500 m-4 rounded-lg font-semibold text-md'> <i class="fa-solid fa-pen-nib mr-2"></i> Register Claim</button>
          }
        </div>
      }

      {
        claimRegistered !== null &&
        <div className='my-1'>
          {
            claimRegistered?.type ? <div id="toast-success" class="mx-auto flex items-center w-fit p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
              <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="sr-only">Check icon</span>
              </div>
              <div class="ms-3 text-sm font-normal">Claim Registered Successfully</div>
              <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                <span class="sr-only">Close</span>
              </button>
            </div> : <div id="toast-danger" class="mx-auto  flex items-center w-fit    p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
              <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                </svg>
                <span class="sr-only">Error icon</span>
              </div>
              <div class="ms-3 text-sm font-normal">{claimRegistered?.msg}</div>
              <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
                <span class="sr-only">Close</span>
              </button>
            </div>
          }
        </div>
      }

    </div>
  )
}

export default ClaimRegistration;
