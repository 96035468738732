const INSURER_OPTIONS = [
  'Bajaj - New Delhi, Delhi',
  'Bajaj - Lucknow, Uttar Pradesh',
  'Bajaj - Bangalore, Karnataka',
  'Bajaj - Pune, Maharashtra',
  'Bajaj - Patna, Bihar',
  'Bajaj - Chennai, Tamil Nadu',
  'Bajaj - Coimbatore, Tamil Nadu',
  'Bajaj - Kolkata, West Bengal',
  'Bajaj - Chandigarh, Punjab',
  'Bajaj - Patna2, Bihar',
  'Bajaj - Ahmedabad, Gujarat',
  'Bajaj - Amaravati, Andhra Pradesh',
  'Bajaj - Muzaffarpur, Bihar',
  'Bajaj - Main',
  'United - New Delhi, Delhi',
  'United - Direct HERO, Karnataka',
  'United - Direct, Karnataka',
  'United - Manipal, Karnataka',
  'United - Girnar2, Delhi',
  'United - Landmark, Delhi',
  'Shriram - New Delhi, Delhi',
  'Royal Sundaram - Bangalore, Karnataka',
  'Tata - New Delhi, Delhi',
  'Magma - Hubli, Karnataka',
  'Magma - Girnar, Delhi',
  'ICICI - New Delhi, Delhi',
  'HDFC - Bangalore, Karnataka',
  'Chola - Hubli, Karnataka',
  'Liberty - Mumbai, Maharashtra',
  'United - policyBazaar, Delihi',
  'Reliance - Landmark, Delhi',
  'Chola - Landmark, Delhi',
  'Bajaj - Landmark84, Delhi',
  'National - Girnar, Delhi',
  'AegisCovenant',
  'Future Generali'
];

const endorsementReasons = [
  'Hypothecation Endorsement',
  'Vehicle Details Endorsement ( Chassis Number / Engine Number )',
  'Model Endorsement',
  'Address Endorsement',
  'Transfer Policy Endorsement',
  'GST Number Endorsement',
  'Addition of Registration Number',
  'AddOn Addition',
  'RTO Endorsement',
  'Customer Details Endorsement',
  'IDV Endorsement',
  'Name Endorsement',
  'Nominee Details Endorsement',
  'Manufacturing Year Endorsement',
  'Vahaan Updation'
];




export { INSURER_OPTIONS, endorsementReasons };